export function PoweredBy({ className }: { className?: string }) {
  return (
    <svg
      viewBox="0 0 85 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M29.5169 4.99219C26.3379 4.99219 23.76 7.6656 23.76 10.9623C23.76 14.2591 26.3379 16.9325 29.5169 16.9325C32.6959 16.9325 35.2739 14.2591 35.2739 10.9623C35.2739 7.6656 32.6959 4.99219 29.5169 4.99219ZM29.5169 14.1332C27.8279 14.1332 26.4594 12.714 26.4594 10.9623C26.4594 9.21071 27.8279 7.79152 29.5169 7.79152C31.206 7.79152 32.5745 9.21071 32.5745 10.9623C32.5745 12.714 31.206 14.1332 29.5169 14.1332Z"
        fill="currentColor"
      />
      <path
        d="M20.0126 8.0199V5.75953H17.3059V16.4217H20.1328V10.9842C20.1328 10.1531 21.1285 8.97946 22.6803 8.97946C22.8394 8.97946 23.0786 9.00087 23.1976 9.06509V5.69531H22.8588C21.4053 5.69531 20.3708 7.18753 20.0126 8.0199Z"
        fill="currentColor"
      />
      <path
        d="M70.1603 8.0199V5.75953H67.4536V16.4217H70.2805V10.9842C70.2805 10.1531 71.2762 8.97946 72.828 8.97946C72.9871 8.97946 73.2263 9.00087 73.3453 9.06509V5.69531H73.0065C71.553 5.69531 70.5185 7.18753 70.1603 8.0199Z"
        fill="currentColor"
      />
      <path
        d="M66.0255 1.60059H63.1392V4.18081H66.0255V1.60059Z"
        fill="currentColor"
      />
      <path
        d="M65.9868 5.75977H63.1599V16.4219H65.9868V5.75977Z"
        fill="currentColor"
      />
      <path
        d="M84.876 11.0686C84.876 7.72025 82.5276 5.24707 79.5016 5.24707C76.1975 5.24707 73.9292 7.72026 73.9292 11.09C73.9292 14.4598 76.2181 16.933 79.98 16.933C82.1098 16.933 83.7018 16.1233 84.7764 15.0781L83.244 13.2446C82.428 14.0128 81.4128 14.5454 80.0395 14.5454C78.3079 14.5454 77.1932 13.6073 76.954 11.8796H84.876V11.0686V11.0686ZM77.0341 9.91766C77.3523 8.53122 78.2083 7.72151 79.4627 7.72151C80.7171 7.72151 81.5731 8.55263 81.8913 9.91766H77.0353H77.0341Z"
        fill="currentColor"
      />
      <path
        d="M16.0407 7.43592C16.0407 7.43592 13.1969 6.11622 10.4866 7.03044C7.77629 7.94467 6.22686 10.7478 6.22686 10.7478C6.22686 10.7478 9.07071 12.0675 11.781 11.1533C12.5788 10.8838 13.2746 10.4519 13.8611 9.97459C12.9188 12.551 10.4914 13.741 8.16364 13.741C5.355 13.741 3.07822 11.3799 3.07822 8.46726C3.07822 5.55459 5.355 3.19348 8.16364 3.19348C9.66086 3.19348 11.0063 3.86467 11.9364 4.93126L13.8975 2.4417C12.4234 0.933109 10.3991 0 8.16364 0C3.655 0 0 3.79037 0 8.466C0 13.1416 3.655 16.932 8.16364 16.932C12.6723 16.932 16.1014 13.1416 16.1014 8.466C16.1014 8.11719 16.0419 7.43341 16.0419 7.43341L16.0407 7.43592Z"
        fill="currentColor"
      />
      <path
        d="M45.9693 15.0808V5.75977H44.1296H43.5565H41.491L38.8438 14.9725V5.75977H36.3181V16.4219H38.3472H38.8438H40.9858L43.5565 7.23688V16.4219H45.5236H45.9693H48.1635L51.0583 5.75977H48.4185L45.9693 15.0808Z"
        fill="currentColor"
      />
      <path
        d="M54.8505 16.4212H52.0637V0.816406H54.7691V7.10389C55.3775 6.31308 56.5141 5.47944 58.0562 5.47944C60.1254 5.47944 61.6153 7.06989 61.6153 9.63122V16.4212H58.7872V10.3742C58.7872 8.77115 57.9154 8.173 56.8601 8.173C55.9264 8.173 54.8517 8.921 54.8517 10.2029V16.4212H54.8505Z"
        fill="currentColor"
      />
    </svg>
  )
}
