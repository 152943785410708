import { Fragment } from 'react'
import { Popover, Transition } from '@headlessui/react'
import { MenuIcon, XIcon } from '@heroicons/react/outline'

import { Hero } from '~/components/frontpage/Hero'
import { Footer } from '~/components/frontpage/Footer'
import { Benefits } from '~/components/frontpage/Benefits'
import { ContentPhotoLeft } from '~/components/frontpage/ContentPhotoLeft'
import { ContentPhotoLeftSticky } from '~/components/frontpage/ContentPhotoLeftSticky'
import { SimpleImageRight } from '~/components/frontpage/SimpleImageRight'
import { JobsGrid } from '~/components/frontpage/JobsGrid'
import { Jobs } from '~/components/frontpage/Jobs'

import type { loadCareerPageData } from '~/careerpage.server'
import type { Block, PageConfiguration } from '~/types'
import { Team } from './Team'
import { Testimonials } from './Testimonials'
import { COMPONENT_MAP } from '~/componentConfiguration'

const navigation = [
  { name: 'Product', href: 'https://coderbyte.com/organizations' },
  { name: 'Company', href: 'https://coderbyte.com/organizations' },
  {
    name: 'Contact',
    href: 'https://share.hsforms.com/1GOMfvkGXS7iMMyZTEIrsUA3kd1p',
  },
]

type Props = {
  configuration: PageConfiguration
  careerPage: Awaited<ReturnType<typeof loadCareerPageData>>['careerPage']
  jobs: Awaited<ReturnType<typeof loadCareerPageData>>['jobs']
}

function renderBlock(
  block: Block,
  jobs: Awaited<ReturnType<typeof loadCareerPageData>>['jobs'],
  careerPage: Awaited<ReturnType<typeof loadCareerPageData>>['careerPage']
) {
  const Component = COMPONENT_MAP[block.component]

  if (!Component) {
    return null
  }

  return (
    <div id={`block-${block.id}`} key={block.id}>
      <Component jobs={jobs} careerPage={careerPage} block={block} />
    </div>
  )
}

export function Index({ careerPage, jobs, configuration }: Props) {
  const logo = (configuration.props!.logoUrl || '').replace(
    'https://s3.us-east-2.amazonaws.com/growhirestaging/',
    'https://d1icxrsvmhiz31.cloudfront.net/'
  )

  const logoLinkUrl = configuration.props!.logoLinkUrl || '/'

  return (
    <div className="gh-container relative bg-white pb-16 pt-6">
      <Popover className="gh-nav-container">
        <nav
          className="relative mx-auto flex max-w-7xl items-center justify-between px-4 sm:px-6"
          aria-label="Global"
        >
          <div className="gh-nav-logo-container">
            <a href={logoLinkUrl} className="flex items-center py-4">
              <img className="h-8 sm:h-10" src={logo} alt="" />
              {configuration.props!.pageTitleVisible && (
                <h1 className="ml-4 text-3xl font-bold text-gray-700">
                  {/* {careerPage?.name} */}
                  {configuration.props.pageTitle}
                </h1>
              )}
            </a>
          </div>
          <div className="gh-nav-links-container hidden md:ml-auto md:block md:space-x-10">
            {(configuration.props.navigationLinks || []).map(
              (item: { label: string; url: string }) => (
                <a
                  key={item.label}
                  href={item.url}
                  className="font-medium text-gray-500 hover:text-gray-900"
                >
                  {item.label}
                </a>
              )
            )}
          </div>
          <div className="gh-nav-right-container text-right">
            {/* <span className="inline-flex rounded-md shadow-md ring-1 ring-black ring-opacity-5">
              <a
                href="#"
                className="inline-flex items-center px-4 py-2 border border-transparent text-base font-medium rounded-md text-indigo-600 bg-white hover:bg-gray-50"
              >
                Log in
              </a>
            </span> */}
          </div>
          <div className="-mr-2 flex items-center md:hidden">
            <Popover.Button className="gh-nav-menu-button inline-flex items-center justify-center rounded-md bg-white p-2 text-gray-400 hover:bg-gray-100 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-accent-color">
              <span className="sr-only">Open main menu</span>
              <MenuIcon className="h-6 w-6" aria-hidden="true" />
            </Popover.Button>
          </div>
        </nav>

        <Transition
          as={Fragment}
          enter="duration-150 ease-out"
          enterFrom="opacity-0 scale-95"
          enterTo="opacity-100 scale-100"
          leave="duration-100 ease-in"
          leaveFrom="opacity-100 scale-100"
          leaveTo="opacity-0 scale-95"
        >
          <Popover.Panel
            focus
            className="absolute inset-x-0 top-0 z-10 origin-top-right transform p-2 transition md:hidden"
          >
            <div className="overflow-hidden rounded-lg bg-white shadow-md ring-1 ring-black ring-opacity-5">
              <div className="flex items-center justify-between px-5 pt-4">
                <div>
                  <a href={logoLinkUrl} className="flex items-center">
                    <img className="h-8 sm:h-10" src={logo} alt="" />
                    {configuration.props!.pageTitleVisible && (
                      <h1 className="ml-4 text-3xl font-bold text-gray-700">
                        {/* {careerPage?.name} */}
                        {configuration.props.pageTitle}
                      </h1>
                    )}
                  </a>
                </div>
                <div className="-mr-2">
                  <Popover.Button className="gh-nav-menu-close-button inline-flex items-center justify-center rounded-md bg-white p-2 text-gray-400 hover:bg-gray-100 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-accent-color">
                    <span className="sr-only">Close main menu</span>
                    <XIcon className="h-6 w-6" aria-hidden="true" />
                  </Popover.Button>
                </div>
              </div>
              <div className="space-y-1 px-2 pb-3 pt-2">
                {(configuration.props.navigationLinks || []).map(
                  (item: { label: string; url: string }) => (
                    <a
                      key={item.label}
                      href={item.url}
                      className="block rounded-md px-3 py-2 text-base font-medium text-gray-700 hover:bg-gray-50 hover:text-gray-900"
                    >
                      {item.label}
                    </a>
                  )
                )}
              </div>
            </div>
          </Popover.Panel>
        </Transition>
      </Popover>
      <main>
        {configuration.blocks.map((block) =>
          renderBlock(block, jobs, careerPage)
        )}
        {/* Hero section */}
        {/* <Hero jobs={jobs} />

        <ContentPhotoLeftSticky />

        <Benefits />

        <ContentPhotoLeft />

        <Jobs jobs={jobs} />

        <SimpleImageRight /> */}
      </main>
      <Footer careerPage={careerPage!} configuration={configuration} />
    </div>
  )
}
